import moment from 'moment'
import * as codes from '@/const/codes.ts'
import { parseBaseRow } from './parseBaseCompanyListExcel'
import { getPortAndIncomeTime } from './parseBaseCompanyListExcel'

export default function (list) {

  const { port, income } = getPortAndIncomeTime()

  const header = [
    '公司名稱',
    '公司英文名稱',
    '統一編號',
    '發行狀態',
    '代表人',
    '公司所在地址',
    '公司所在地址(英文)',
    '電話',
    'email',
    '資本總額(元)',
    '實收資本總額(元)',
    '公司狀況',
    '核准設立日期',
    '營業項目',
    `${port.lastOneYear}進口實績`,
    `${port.lastTwoYear}進口實績`,
    `${port.lastThreeYear}進口實績`,
    `${port.lastOneYear}出口實績`,
    `${port.lastTwoYear}出口實績`,
    `${port.lastThreeYear}出口實績`,
    // '電子郵件',
    // '登記機關',
    '營業狀況',
    `${income.lastOneYear}營業收入`,
    `${income.lastTwoYear}營業收入`
  ]

  const data = list.map((row, i) => {
    return parseBaseRow(row, port, income)
  })

  return {
    header,
    data
  }
}