import moment from 'moment'
import * as codes from '@/const/codes.ts'

export function getPortAndIncomeTime () {
  const thisYear = moment().year()
  const lastYear = thisYear - 1
  const theYearBeforLast = lastYear - 1

  // 取得進出口年分
  const port = {
    lastOneYear: thisYear,
    lastTwoYear: lastYear,
    lastThreeYear: theYearBeforLast
  }

  // 取得ifrss年份
  const income = {
    lastOneYear: lastYear - 1911, // 農曆年
    lastTwoYear: theYearBeforLast - 1911 // 農曆年
  }
  if ((moment().month() + 1) < 4) {
    // 3月底後才會全部公司公佈去年度財報資料
    income.lastOneYear -=1
    income.lastTwoYear -=1
    // 3月底後才會有今年的第一季度進出資料
    port.lastOneYear -= 1
    port.lastTwoYear -= 1
    port.lastThreeYear -= 1
  }
  return { port, income }
}

export function parseBaseRow (row, port, income) {
  let portLastOneYearImport = ''
  let portLastTwoYearImport = ''
  let portLastThreeYearImport = ''
  let portLastOneYearExport = ''
  let portLastTwoYearExport = ''
  let portLastThreeYearExport = ''
  if (row.ports) {
    if (row.ports[port.lastOneYear]) {
      let importCode = row.ports[port.lastOneYear].import
      let exportCode = row.ports[port.lastOneYear].export
      if (codes.IMPORT_AND_EXPORT_DATA[importCode]) {
        portLastOneYearImport = codes.IMPORT_AND_EXPORT_DATA[importCode].label
      }
      if (codes.IMPORT_AND_EXPORT_DATA[exportCode]) {
        portLastOneYearExport = codes.IMPORT_AND_EXPORT_DATA[exportCode].label
      }
    }
    if (row.ports[port.lastTwoYear]) {
      let importCode = row.ports[port.lastTwoYear].import
      let exportCode = row.ports[port.lastTwoYear].export
      if (codes.IMPORT_AND_EXPORT_DATA[importCode]) {
        portLastTwoYearImport = codes.IMPORT_AND_EXPORT_DATA[importCode].label
      }
      if (codes.IMPORT_AND_EXPORT_DATA[exportCode]) {
        portLastTwoYearExport = codes.IMPORT_AND_EXPORT_DATA[exportCode].label
      }
    }
    if (row.ports[port.lastThreeYear]) {
      let importCode = row.ports[port.lastThreeYear].import
      let exportCode = row.ports[port.lastThreeYear].export
      if (codes.IMPORT_AND_EXPORT_DATA[importCode]) {
        portLastThreeYearImport = codes.IMPORT_AND_EXPORT_DATA[importCode].label
      }
      if (codes.IMPORT_AND_EXPORT_DATA[exportCode]) {
        portLastThreeYearExport = codes.IMPORT_AND_EXPORT_DATA[exportCode].label
      }
    }
  }
  let etaxMatters = ''
  if (row.etax_matters_tideup && row.etax_matters_tideup.length) {
    etaxMatters = row.etax_matters_tideup
      .map(d => {
        return `${d.title}(${d.code})`
      })
      .join('、')
  }

  let itemIncome = {}
  if (row.publics && row.publics.IFRSs_str) {
    const itemIfrss = JSON.parse(row.publics.IFRSs_str)
    itemIncome = itemIfrss['營業收入'] || {}
  }    

  return [
    row.company_name || '',
    row.company_name_en_MOEA || '',
    row.uniID || '',
    row.public_issue || '',
    row.representative_name || '',
    row.company_address || '',
    // addressEnList[i],
    row.company_address_translated_in_english  || '',
    (row.multi && row.multi.phone && row.multi.phone[0] && row.multi.phone[0].value) ? row.multi.phone[0].value : '',
    (row.multi && row.multi.email && row.multi.email[0] && row.multi.email[0].value) ? row.multi.email[0].value : '',
    row.capital_amount < 0 ? 0 : row.capital_amount || '',
    row.paidup_capital_amount < 0 ? 0 : row.paidup_capital_amount || '',
    row.company_state,
    moment(row.date_approved).format('YYYY/MM/DD') || '',
    etaxMatters || '',
    portLastOneYearImport,
    portLastTwoYearImport,
    portLastThreeYearImport,
    portLastOneYearExport,
    portLastTwoYearExport,
    portLastThreeYearExport,
    // (row.multi && row.multi.email && row.multi.email[0] && row.multi.email[0].value) ? row.multi.email[0].value : '',
    // row.registration_department || '',
    row.company_operate || '',
    itemIncome[`${income.lastOneYear}年度`] || '',
    itemIncome[`${income.lastTwoYear}年度`] || '',
  ]
}